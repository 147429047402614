<template>
    <div class="page-wrap">
        <div class="first-in" v-if="showDetail">
            <div class="check-item" @click="changeType('1')">
                <div class="iconfont icon-a-01"></div>
                <div class="word">发起合同</div>
            </div>
            <div v-if="$store.state.auth.indexOf('approval-sign') != -1">
                <div class="check-item" v-if="$store.state.identity == 1||$store.state.identity == 3" @click="changeType('2')">
                    <div class="iconfont icon-a-02"></div>
                    <div class="word">批量签署</div>
                </div>
            </div>
        </div>
        <div v-else>
            <Ordinary v-if="showType === '1'" @goBack="closeComponent"></Ordinary>
            <Batch v-if="showType === '2'" @goBack="closeComponent"></Batch>
        </div>
    </div>
</template>

<script>
import Batch from './component/batch'
import Ordinary from './component/ordinary'
export default {
    name: "index",
    components:{
        Batch,
        Ordinary
    },
    data(){
        return{
            showDetail:true,
            showType:''
        }
    },
    created() {
    },
    methods:{
        closeComponent(){
            this.showDetail = true
        },
        changeType(type){
            this.showDetail = false
            this.showType = type
        }
    }
}
</script>

<style scoped lang="scss">
.page-wrap {
    .top-change {
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        position: relative;
        height: 60px;
        margin-bottom: 20px;
        .l-where {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1B2742;
            span {
                color: #4877E8;
            }
        }
        .r-back {
            .iconfont {
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 26px;
                cursor: pointer;
                &:hover {
                    color: #4877E8;
                }
            }
        }
    }
    .first-in {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        .check-item {
            width: 280px;
            height: 340px;
            background: #FFFFFF;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 20px;
            border: 2px solid #fff;
            box-sizing: border-box;
            &:hover {
                border: 2px solid #4877E8;
            }
            .iconfont {
                font-size: 80px;
                color: #6D92ED;
            }
            .word {
                margin-top: 12px;
                font-size: 28px;
                color: #545F78;
            }
        }
    }
    .choose-first {
        .top-nav {
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            // .nav-title {
            // }
            .input-group {
                margin-top: 20px;
                .wrap {
                    margin-right: 20px;
                }
                .label {
                    font-size: 14px;
                    color: #545F78;
                }
            }
        }
        .have-sel {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }
        .middle-nav {
            width: 25%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
        }
        .middle-r {
            width: 73%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .upload-style-i {
                margin-top: unset;
            }
        }
        .third-nav {
            margin-top: 20px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .table {
                width: 100%;
                border: 1px solid #E4EAF9;
                border-radius: 7px;
                margin-top: 20px;
                display: flex;
                .first-item {
                    width: 18%;
                }
                .second-item {
                    width: 24%;
                }
                .third-item {
                    flex: 1;
                }
                .tab-label {
                    height: 46px;
                    background: #F6F7FC;
                    font-size: 16px;
                    font-weight: bold;
                    color: #1B2742;
                }
                .tab-val {
                    width: 100%;
                    height:80px ;
                }
            }
        }
        .company {
            margin-top: 20px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .more-del {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #E4EAF9;
                border-radius: 7px;
                margin-top: 12px;
                padding: 20px;
                box-sizing: border-box;
                .input-nav {
                    .wrap {
                        flex: 1;
                    }
                    .label {
                        font-size: 14px;
                        font-weight: bold;
                        color: #1B2742;
                    }
                }
                .check-nav {
                    margin-top: 20px;
                    display: flex;
                    .label {
                        font-size: 14px;
                        font-weight: bold;
                        color: #1B2742;
                    }
                    .last-btn {
                        width: 100px;
                        height: 30px;
                        background: #FFFFFF;
                        border: 1px solid #4877E8;
                        border-radius: 6px;
                        font-size: 11px;
                        color: #4877E8;
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
        .end-btn {
            margin-top: 20px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 12px;
            padding: 21px 20px;
            box-sizing: border-box;
            .wrap {
                margin-left: 60px;
                .label {
                    font-size: 14px;
                    font-weight: bold;
                    color: #1B2742;
                }
            }
        }
        .moreSign {
            margin-top: 20px;
            .select-it {
                width: 32%;
                height: 128px;
                background: #FFFFFF;
                border-radius: 12px;
                cursor: pointer;
                border: 2px solid #fff;
                box-sizing: border-box;
                &:hover {
                    border: 2px solid #4877E8;
                }
                .icon-pic {
                    font-size: 40px;
                    color: #4877E8;
                }
                .pic-name {
                    font-size: 21px;
                    font-weight: bold;
                    color: #1B2742;
                    margin-left: 10px;
                }
            }
        }
    }
    .submit-btn {
        width: 100%;
        margin-top: 20px;
        height: 110px;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 12px;
        border: 2px solid #DBDEE6;
        box-sizing: border-box;
        &:hover {
            border: 2px solid #4877E8;
        }
        .iconfont {
            font-size: 50px;
            color: #4877E8;
        }
        span {
            font-size: 28px;
            font-weight: bold;
            color: #1B2742;
            margin-left: 12px;
        }
    }
    .submit-btn-else {
        border: unset;
    }
    .upload-style-i {
        margin-top: 20px;
        .up-del {
            width: 170px;
            height: 228px;
            background: #FFFFFF;
            border: 1px solid #C8CEDA;
            border-radius: 10px;
            .up-img {
                    width: 105px;
                    height: 110px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .up-btn {
            width: 90px;
            height: 30px;
            background: #4877E8;
            border-radius: 4px;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
            margin-top: 12px;
        }
    }
    .else-up {
        display: flex;
        .upload-style {
            width: 100px;
            height: 100px;
            border-radius: 4px;
            border: 1px dashed #8a8f97;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
        }
        .pic-wrap {
            display: flex;
            flex-wrap: wrap;
            margin-left: 12px;
        }
        .img-list {
            width: 100px;
            height: 100px;
            position: relative;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 4px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
            }
            .close {
                position: absolute;
                top: -15px;
                right: -15px;
                cursor: pointer;
                color: #dd0000;
                display: none;
            }
        }
        .img-list:hover .close {
            display: inline-block;
        }
    }
}

</style>
